<script>
/* COMPONENT DOCUMENT
 * author: liyulong
 * date: 2020/06/01
 * desc: 已取得安全证书转基因农作物南繁试验申请
 */

import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';

export default {
  name: '',
  components: {
    nfBreadcrumb
  },
  created() {
    if (this.$route.query.id) {
      this.is_disabeld = true;
      this.rules = {};
      this.getDetail();
    }
  },

  data() {
    return {
      breadList: [
        { name: '转基因申报', path: '/transgenicRegistration' },
        { name: '已取得安全证书转基因农作物南繁试验申请' }
      ],
      is_disabeld: false,
      register_form: {
        genre: 1,
        biologyName: '', // 转基因生物名称
        varietyName: '', // 受体品种名称
        transgeneExternal: [{ gene: '', function: '' }], // 外源基因
        credentialNumber: '', // 证书编号
        credentialIndate: '', // 有效期
        credentialScope: '', // 使用范围
        transgeneExperiment: [{ // 南繁试验
          experimentTime: [], // 时间
          experimentScale: '', // 规模
          experimentSite: '', // 地点
          experimentType: '' // 类型
        }],
        unitName: '', // 单位名称
        legalPerson: '', // 法人代表
        applicant: '', // 申请人
        location: '' // 联系地址
      },
      rules: {
        biologyName: [{ required: true, message: '请输入转基因生物名称', trigger: 'change' }],
        varietyName: [{ required: true, message: '请输入受体品种名称', trigger: 'change' }],
        credentialNumber: [{ required: true, message: '请输入证书编号', trigger: 'change' }],
        credentialIndate: [{ required: true, message: '请输入有效期', trigger: 'change' }],
        credentialScope: [{ required: true, message: '请输入使用范围', trigger: 'change' }],
        unitName: [{ required: true, message: '请输入单位名称', trigger: 'change' }],
        legalPerson: [{ required: true, message: '请输入法人代表', trigger: 'change' }],
        applicant: [{ required: true, message: '请输入申请人', trigger: 'change' }],
        location: [{ required: true, message: '请输入联系地址', trigger: 'change' }]
      },
      // 时间-禁选
      pickerOptions: {
        disabledDate: (time) => {
          return Date.now() > time.getTime();
        }
      }
    };
  },

  methods: {
    /** 获取详情 */
    async getDetail() {
      const api = '/api/declare/transgene/registerDetail/' + this.$route.query.id;
      const data = await this.$fetchData.fetchPost({}, api, 'json');

      if (data.code === '200') {
        this.register_form = data.result;
        this.register_form.transgeneExperiment = this.register_form.transgeneExperiment.reduce((pre, cur) => {
          pre.push({
            experimentTime: [cur.startTime, cur.endTime], // 规模
            experimentScale: cur.experimentScale, // 规模
            experimentSite: cur.experimentSite, // 地点
            experimentType: cur.experimentType // 类型
          });
          return pre;
        }, []);
      } else {
        this.$message({ type: 'error', message: data.message });
      }
    },

    /** 新增基因 */
    addGene() {
      this.register_form.transgeneExternal.push({ gene: '', function: '' });
    },
    /** 删除基因 */
    deleteFene(index) {
      this.$confirm('是否删除当前基因?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.register_form.transgeneExternal.splice(index, 1);
      }).catch(() => {});
    },

    /** 新增试验 */
    addTest() {
      this.register_form.transgeneExperiment.push({
        experimentTime: [], // 时间
        experimentScale: '', // 规模
        experimentSite: '', // 地点
        experimentType: '' // 类型
      });
    },
    /** 删除基因 */
    deleteTest(index) {
      this.$confirm('是否删除当前试验?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.register_form.transgeneExperiment.splice(index, 1);
      }).catch(() => {});
    },

    /** 提交 */
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) { return false; }
        this.register_form.transgeneExperiment = this.register_form.transgeneExperiment.reduce((pre, cur) => {
          pre.push({
            startTime: cur.experimentTime[0], // 开始时间
            endTime: cur.experimentTime[1], // 结束时间
            experimentScale: cur.experimentScale, // 规模
            experimentSite: cur.experimentSite, // 地点
            experimentType: cur.experimentType // 类型
          });
          return pre;
        }, []);
        const data = await this.$fetchData.fetchPost(this.register_form, '/api/declare/transgene/addRegister', 'json');

        if (data.code === '200') {
          this.$message({ type: 'success', message: data.message });
          this.$router.push({ name: 'transgenicRegistration' });
        } else {
          this.$message({ type: 'error', message: data.message });
        }
      });
    }
  }
};
</script>

<template>
<div class="module_registration">
  <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>

  <div class="content">
    
    <el-form :model="register_form" ref="ruleForm" :rules="rules" label-width="125px">
      <div class="title clearfix">
        <span class="first fl"></span>
        <span class="second fl">已取得安全证书转基因农作物南繁试验申请</span>
      </div>
      <el-form-item label="转基因生物名称" prop="biologyName" class="line_form">
        <el-input maxlength="50" v-model="register_form.biologyName" placeholder="请输入转基因生物名称" :disabled="is_disabeld" />
      </el-form-item>
      <el-form-item label="受体品种名称" prop="varietyName" class="line_form">
        <el-input maxlength="50" v-model="register_form.varietyName" placeholder="请输入受体品种名称" :disabled="is_disabeld" />
      </el-form-item>
      
      <div class="title clearfix">
        <span class="first fl"></span>
        <span class="second fl">外源基因</span>
      </div>
      <div class="test_box" v-for="(item, index) in register_form.transgeneExternal" :key="index">
        <div class="top clearfix">
          <span class="fl top_name">{{ '基因' + (index + 1) }}</span>
          <template v-if="!is_disabeld">
            <el-button class="fr" type="text" v-if="index === 0" @click="addGene">+ 新增基因</el-button>
            <span class="delete fr" v-else @click="deleteFene(index)">删除</span>
          </template>
        </div>
        <el-form-item label="基因名称" prop="type" class="line_form">
          <el-input maxlength="50" v-model="item.gene" placeholder="请输入" :disabled="is_disabeld" />
        </el-form-item>
        <el-form-item label="功能" prop="type" class="line_form">
          <el-input maxlength="50" v-model="item.function" placeholder="请输入" :disabled="is_disabeld" />
        </el-form-item>
      </div>
      
      <div class="title clearfix">
        <span class="first fl"></span>
        <span class="second fl">安全证书</span>
      </div>
      <el-form-item label="证书编号" prop="credentialNumber" class="line_form">
        <el-input maxlength="100" v-model="register_form.credentialNumber" placeholder="请输入" :disabled="is_disabeld" />
      </el-form-item>
      <el-form-item label="使用范围" prop="credentialScope" class="line_form">
        <el-input maxlength="100" v-model="register_form.credentialScope" placeholder="请输入" :disabled="is_disabeld" />
      </el-form-item>
      <el-form-item label="有效期" prop="credentialIndate" class="line_form">
        <el-date-picker type="date" placeholder="选择日期" v-model="register_form.credentialIndate"
          value-format="yyyy-MM-dd" :editable="false" :disabled="is_disabeld" :picker-options="pickerOptions" />
      </el-form-item>
      
      <div class="title clearfix">
        <span class="first fl"></span>
        <span class="second fl">南繁试验申请事项</span>
      </div>
      <div class="test_box" v-for="(item, index) in register_form.transgeneExperiment" :key="'test' + index">
        <div class="top clearfix">
          <span class="fl top_name">{{ '试验' + (index + 1) }}</span>
          <template v-if="!is_disabeld">
            <el-button class="fr" type="text" v-if="index === 0" @click="addTest">+ 新增试验</el-button>
            <span class="delete fr" v-else @click="deleteTest(index)">删除</span>
          </template>
        </div>
        <el-form-item label="时间" prop="type" class="line_form">
          <el-date-picker
            v-model="item.experimentTime"
            type="daterange"
            :editable="false"
            :disabled="is_disabeld"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="地点" prop="experimentSite" class="line_form">
          <el-input maxlength="50" v-model="item.experimentSite" placeholder="请输入" :disabled="is_disabeld" />
        </el-form-item>
        <el-form-item label="规模" prop="experimentScale" class="line_form">
          <el-input maxlength="100" v-model="item.experimentScale" placeholder="请输入" :disabled="is_disabeld" />
        </el-form-item>
        <el-form-item label="类型" prop="experimentType" class="line_form">
          <el-input maxlength="100" v-model="item.experimentType" placeholder="请输入" :disabled="is_disabeld" />
        </el-form-item>
      </div>
      
      <div class="title clearfix">
        <span class="first fl"></span>
        <span class="second fl">申请单位（个人）情况</span>
      </div>
      <el-form-item label="单位名称" prop="unitName" class="line_form">
        <el-input maxlength="50" v-model="register_form.unitName" placeholder="请输入" :disabled="is_disabeld" />
      </el-form-item>
      <el-form-item label="法人代表" prop="legalPerson" class="line_form">
        <el-input maxlength="50" v-model="register_form.legalPerson" placeholder="请输入" :disabled="is_disabeld" />
      </el-form-item>
      <el-form-item label="申请人" prop="applicant" class="line_form">
        <el-input maxlength="50" v-model="register_form.applicant" placeholder="请输入" :disabled="is_disabeld" />
      </el-form-item>
      <el-form-item label="联系地址" prop="location" class="line_form">
        <el-input maxlength="50" v-model="register_form.location" placeholder="请输入" :disabled="is_disabeld" />
      </el-form-item>

      <el-form-item v-if="!is_disabeld">
        <el-button type="primary" class="submit_btn" @click="submit">提 交</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<style lang="less">
.module_registration {
  padding-bottom: 20px;
  .content{
    font-size: 16px;
    color: #333;
    width: 1200px;
    margin: 0 auto;
    .title{
      font-size: 18px;
      padding-bottom: 20px;
      border-bottom: 1px solid #d9d9d9;
      margin:30px 0 20px;
    }
    .first{
      display: inline-block;
      width:6px;
      height:20px;
      background-color: #265ae1;
      margin-right:10px;
    }
    .second{
      line-height: 20px;
    }
    .line_form {
      width: 45%;
      display: inline-block;
    }
    .top {
      background-color: #ecf5ff;
      padding: 10px 100px 10px 50px;
      margin-bottom: 20px;
      button {
        padding: 0;
      }
      .top_name {
        color: #409EFF;
        font-weight: 700;
      }
    }
    .delete {
      font-size: 14px;
      color: #f56c6c;
      cursor: pointer;
    }
    .submit_btn {
      display: block;
      width: 200px;
      margin: 20px auto;
      transform: translateX(-50%);
    }
    /deep/ .el-input__inner{
      width: 400px;
    }
    /deep/ .el-input{
      width: 400px;
    }
  }
  .test_box {
    border-bottom: 1px solid #e4e7ed;
    margin-bottom: 20px;
  }
  .el-input.is-disabled .el-input__inner,
  .el-range-editor.is-disabled,
  .el-range-editor.is-disabled .el-range-input,
  .el-range-editor.is-disabled .el-input__icon,
  .el-textarea.is-disabled .el-textarea__inner {
    color: #606266;
    background-color: #FFF;
  }
}

</style>
